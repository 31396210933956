<template class="max-w-full">
  <div class="max-w-full">
    <v-calendar :masks="masks" :attributes="attributes" class="custom-calendar max-w-full mt-4">
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full overflow-hidden">
          <div v-if="day.isToday" class="day-label font-bold">
            <span class="rounded-full px-3 py-1 -ml-2 text-white" style="background-color: rgb(15 59 168);">{{ day.day }}</span>
          </div>
          <div v-else class="day-label">{{ day.day }}</div>
          <div class="overflow-y-auto mt-2">
            <p v-for="attr in attributes" :key="attr.key">
              <span :id="'cal' + attr.customData.calendar_id" @click.self="calClick(attr.key)" @mousemove="hover(day, attr, $event)" @mouseleave="hoverOut()" v-if="attr.customData.start_date == (day.day + '-' + day.month)" class="mx-2 px-3" :class="attr.customData.class">
                <span class="truncrate" @click.self="calClick(attr.key)">{{ attr.customData.title }}</span>
              </span>
              <span :id="'cal' + attr.customData.calendar_id" @click.self="calClick(attr.key)" @mousemove="hover(day, attr, $event)" @mouseleave="hoverOut()" v-else-if="attr.customData.end_date == (day.day + '-' + day.month)" class="mx-2 px-3 font-bold" :class="attr.customData.class">{{ attr.customData.time }}</span> 
            </p>
          </div>
        </div>
      </template>
    </v-calendar>
    <div v-show="visible" class="hover-block" id="hover">
      <p class="text-center font-bold mt-2" id="hover-date"></p>
      <ul id="hover-text"></ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Calendar",
  props: ["calendars", "attributes"],
  data() {
    return {
      masks: { weekdays: "WWW" },
      visible: false
    }
  },
  methods: {
    calClick(calId) {
      this.$router.push(`/calendar/${calId}`)
    },
    hover(dateHover, attr, event) {
      document.getElementById('hover-text').innerHTML = ""
      const monthNames = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤศภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]
      this.visible = true
      var date = dateHover.id.split('-')
      var timestamp = new Date(date[0], date[1]-1, date[2])
      var day = timestamp.getDate()
      var month = monthNames[timestamp.getMonth()]
      var year = timestamp.getFullYear() + 543
      document.getElementById('hover-date').innerHTML = day + " " + month + " " + year
      var li = document.createElement('li')
      li.innerHTML = attr.customData.title
      li.classList.add("pt-2")
      li.classList.add("pb-4")
      document.getElementById('hover-text').appendChild(li)
      if (event.pageX > 800 && attr.customData.title.length >= 13) {
        if (event.pageX >= 1280 && attr.customData.title.length >= 13 && attr.customData.title.length < 20) {
          if (attr.customData.title.length >= 13 && attr.customData.title.length <= 14) {
            document.getElementById('hover').style.left = (event.pageX-200) + 'px'
          } else if (attr.customData.title.length >= 15) {
            document.getElementById('hover').style.left = (event.pageX-220) + 'px'
          }
        }
        else if (event.pageX >= 1250 && attr.customData.title.length >= 20 && attr.customData.title.length < 30) {
          document.getElementById('hover').style.left = (event.pageX-240) + 'px'
        }
        else if(event.pageX >= 1200 && attr.customData.title.length >= 30 && attr.customData.title.length < 40) {
          document.getElementById('hover').style.left = (event.pageX-300) + 'px'
        }
        else if (event.pageX >= 1100 && attr.customData.title.length >= 40  && attr.customData.title.length < 70) {
          document.getElementById('hover').style.left = (event.pageX-380) + 'px'
        }
        else if (attr.customData.title.length >= 70) {
          document.getElementById('hover').style.left = (event.pageX-700) + 'px'
        }
        else {
          document.getElementById('hover').style.left = event.pageX + 'px'
        }
      } else {
        document.getElementById('hover').style.left = event.pageX + 'px'
      }
      document.getElementById('hover').style.left = event.pageX
      document.getElementById('hover').style.top = event.pageY < 1100 ? event.pageY + 'px' : event.pageY-120 + 'px'
    },
    hoverOut() {
      this.visible = false
      document.getElementById('hover-text').innerHTML = ""
    }
  }
}
</script>

<style>
.vc-day {
  position: relative;
  min-height: var(--day-min-height);
  width: 100%;
  height: 20%;
  z-index: 1;
}

.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 100px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}

.custom-calendar.vc-container .vc-weeks {
  padding: 0;
  grid-template-rows: 0px repeat(6, 0fr);
}

.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}

.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}


.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}

.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}

.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}

.custom-calendar.vc-container .vc-day {
  text-align: left;
  min-height: var(--day-height);
  min-width: var(--day-width);
  background-color: #fff;
}

.day-label {
  @apply text-sm text-gray-900 pt-2 pl-4;
}

.truncrate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hover-block {
  background-color: black;
  @apply border-2 rounded-lg mt-4 border-black absolute z-10 px-10 py-2 text-center text-white;
}
</style>
