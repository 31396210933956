<template>
  <div class="main">
    <NavBar/>
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Warning -->
      <div class="text-red-500 mx-auto mb-8 sm:text-sm xl:text-base">*ระบบจะทำการแจ้งเตือนผู้ใช้งานระบบผ่านทางอีเมลของคณะถึงกิจกรรมต่าง ๆ ที่เกี่ยวข้องกับผู้ใช้งานระบบ กรุณาล็อกอินอีเมลของคณะเพื่อรับการแจ้งเตือน*</div>
      
      <!-- Announcement -->
      <div class="row my-auto">
        <div class="header">ประกาศ</div>

        <!-- Type filter -->
        <p class="my-auto sm:ml-16 xl:ml-32">รายวิชา</p>
        <select class="select-bar-mini px-4 my-auto" v-model="filter.ann_type" @change="changeType(filter.ann_type, true)">
          <option value="PJ">โครงงาน</option>
          <option value="CP">สหกิจศึกษา</option>
          <option value="IS">ศึกษาอิสระ</option>
        </select>

        <!-- Program filter -->
        <p class="my-auto ml-8">หลักสูตร</p>
        <select class="select-bar my-auto" v-model="filter.ann_pg" @change="announcementFilter()">
          <option v-for="program in ann_programs" :key="program.code" :value="program.code">{{ program.name }}</option>
        </select>

        <router-link v-if="role === 'STF'" :to="'/announcement/add/'" class="ml-auto">
          <button class="file-button">เพิ่มประกาศ</button>
        </router-link>
      </div>

      <!-- Announcement -->
      <div class="announcement">
        <!-- Document -->
        <div class="announcement-block">
          <router-link :to="'/document/' + filter.ann_type">
            <p class="document-header">คู่มือและเอกสารแบบฟอร์มต่าง ๆ ของรายวิชา{{ filter.ann_name }}</p>
          </router-link>
        </div>
        <!-- Announcement -->
        <div v-if="filter.announcements.length > 0">
          <div v-for="ann in filter.announcements" :key="ann.id" class="announcement-block">
            <router-link :to="'/announcement/' + ann.id">
              <div v-html="ann.header" class="announcement-header"></div>
              <div class="announcement-date">เมื่อวันที่ {{ ann.created }} น.</div>
            </router-link>
          </div>
        </div>
      </div>

    <!-- Calendar -->
    <div class="row mt-40">
      <div class="header">ปฏิทินการศึกษา</div>

      <!-- Type filter -->
      <p class="my-auto sm:ml-4 xl:ml-16">รายวิชา</p>
      <select v-model="filter.cal_type" @change="changeType(filter.cal_type, false)" class="select-bar-mini px-4 my-auto">
        <option value="PJ">โครงงาน</option>
        <option value="CP">สหกิจศึกษา</option>
        <option value="IS">ศึกษาอิสระ</option>
      </select>

      <!-- Program filter -->
      <p class="my-auto ml-8">หลักสูตร</p>
      <select v-model="filter.cal_pg" @change="calendarFilter()" class="select-bar px-4 my-auto">
        <option v-for="program in cal_programs" :key="program.code" :value="program.code">{{ program.name }}</option>
      </select>

      <router-link v-if="role === 'STF'" :to="'/calendar/add'" class="ml-auto">
        <button class="file-button">เพิ่มกิจกรรม</button>
      </router-link>
    </div>
    <!-- Calendar -->
    <Calendar :calendars="calendars" :attributes="attributes" />
    </div> 
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import Calendar from "@/components/Calendar"
import moment from "moment"
import { changeProgramName, changeProgramByType } from "../mixins/other"

export default {
  name: "Home",
  components: { 
    NavBar,
    Calendar
  },
  data() {
    return {
      loading: true,
      attributes: [],
      announcements: [],
      calendars: [],
      ann_programs: [],
      cal_programs: [],
      filter: {
        ann_type: "PJ",
        ann_pg: "IT",
        ann_name: "",
        announcements: [],
        cal_type: "PJ",
        cal_pg: "IT"
      },
      role: null,
    }
  },
  async created() {
    document.title = "หน้าหลัก | Carbon"
    this.role = this.$store.getters.payload.role
    this.ann_programs = await this.$store.dispatch("getProgram")
    this.cal_programs = await this.$store.dispatch("getProgram")
    let announcements = await this.$store.dispatch("getAnnouncement")
    this.announcements = announcements.filter(ann => ann.flag == "A")
    this.calendars = announcements.filter(cal => cal.flag == "C")
    await this.announcementFilter()
    this.announcements.filter((ann) => ann.created = moment(new Date(ann.created)).locale('th').add(543, 'year').format('lll'))
    await this.calendarFilter()
    this.loading = false
  },
  methods: {
    announcementFilter() {
      this.filter.announcements = []
      this.ann_programs = changeProgramName(changeProgramByType(this.filter.ann_type))
      
      this.announcements.filter((ann) => {
        if (ann.program_detail.code == this.filter.ann_pg && ann.course_type == this.filter.ann_type) {
          this.filter.announcements.push(ann)
        }
      })

      this.filter.announcements.sort(function(a, b) { return new Date(b.created) - new Date(a.created) })
      this.filter.ann_name = this.filter.ann_type == "PJ" ? "โครงงาน" : this.filter.ann_type == "CP" ? "สหกิจศึกษา" : "ศึกษาอิสระ"
    },
    calendarFilter() {
      var filter_attributes = []
      this.attributes = []
      let index = 0
      let color = ["bg-red-500", "bg-green-700", "bg-pink-700", "bg-yellow-800", "bg-purple-700"]
      this.cal_programs = changeProgramName(changeProgramByType(this.filter.cal_type))
      for (let cal in this.calendars) {
        if(index == 5) { index = 0 }
        let tmp = {}
        let time = moment(new Date(this.calendars[cal].active_date_to)).format('HH:mm:ss')
        time = "DEADLINE: " + time
        tmp["key"] = this.calendars[cal].id
        tmp["dates"] = {
          start: new Date(this.calendars[cal].active_date_from),
          end: new Date(this.calendars[cal].active_date_to)
        } 
        tmp["customData"] = {
          calendar_id: this.calendars[cal].id,
          title: this.calendars[cal].header,
          class: this.calendars[cal].form_entry || this.calendars[cal].is_101 ? "calendar-block " + color[index] : "calendar-block bg-blue-500",
          style: "color:red",
          start_date: tmp["dates"]["start"].getDate() + "-" + (tmp["dates"]["start"].getMonth() + 1),
          end_date: tmp["dates"]["end"].getDate() + "-" + (tmp["dates"]["end"].getMonth() + 1),
          is_same_range: false,
          end_date_before: null,
          program_code: this.calendars[cal].program_detail.code,
          course_type: this.calendars[cal].course_type,
          time: time
        }
        this.attributes.push(tmp)
        index += 1
      }
      
      this.attributes.filter((cal) => {
        if (cal.customData.program_code == this.filter.cal_pg && cal.customData.course_type == this.filter.cal_type) {
          filter_attributes.push(cal)
        }
      })

      filter_attributes.sort(function(a,b) { return a.dates.start - b.dates.start })
      this.attributes = filter_attributes
    },
    changeType(type, isAnnouncement) {
      if (isAnnouncement) {
        this.ann_programs = changeProgramName(changeProgramByType(this.filter.ann_type))
        if (type == "IS") {
          this.filter.ann_pg = "DTI"
        } else {
          this.filter.ann_pg = "IT"
        }
        this.announcementFilter()
      } else {
        this.cal_programs = changeProgramName(changeProgramByType(this.filter.cal_type))
        if (type == "IS") {
          this.filter.cal_pg = "DTI"
        } else {
          this.filter.cal_pg = "IT"
        }
        this.calendarFilter()
      }
    }
  }
}
</script>